import React, { useState } from 'react';
import InputV2 from 'components/Input/InputV2';
import Button from 'components/Button/Button';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { addBonusPayment } from 'actions/reconciles/reconciles';
import { connect } from 'react-redux';
import { wfUserAgent } from 'helpers';

const currencyOptions = [
  {
    label: 'USD - United States Dollar',
    value: 'USD',
  },
  {
    label: 'EUR - Euro',
    value: 'EUR',
  },
  {
    label: 'JPY - Japanese Yen',
    value: 'JPY',
  },
  {
    label: 'GBP - British Pound Sterling',
    value: 'GBP',
  },
  {
    label: 'AUD - Australian Dollar',
    value: 'AUD',
  },
  {
    label: 'CAD - Canadian Dollar',
    value: 'CAD',
  },
  {
    label: 'CHF - Swiss Franc',
    value: 'CHF',
  },
  {
    label: 'CNY - Chinese Yuan Renminbi',
    value: 'CNY',
  },
  {
    label: 'SEK - Swedish Krona',
    value: 'SEK',
  },
  {
    label: 'NZD - New Zealand Dollar',
    value: 'NZD',
  },
];

interface AddBonusPaymentsFormValues {
  commissionAmount: string;
  deviceId: string;
  eventDate: string;
  merchantOrderId: string;
  modifiedAuthor: string;
  networkId: string;
  networkMerchantId: string;
  saleAmount: string;
  saleCurrencyCode: string;
  trackingCode: string;
}

interface AddBonusPaymentsData {
  CommissionAmount: number;
  DeviceID: number;
  EventDate: string;
  MerchantOrderID: string;
  ModifiedAuthor: string;
  NetworkID: number;
  NetworkMerchantID: number;
  SaleAmount: number;
  SaleCurrencyCode: string;
  TrackingCode: string;
}

const defaultAddBonusPaymentsForm = {
  commissionAmount: '',
  deviceId: '',
  eventDate: '',
  merchantOrderId: '',
  networkId: '',
  networkMerchantId: '',
  saleAmount: '',
  saleCurrencyCode: '',
  trackingCode: '',
};

const defaultAddBonusPaymentsErrors = {
  commissionAmount: false,
  deviceId: false,
  eventDate: false,
  merchantOrderId: false,
  networkId: false,
  networkMerchantId: false,
  saleAmount: false,
  saleCurrencyCode: false,
  trackingCode: false,
};

interface AddBonusPaymentsFormProps {
  addBonusPayment: (data: AddBonusPaymentsData) => Promise<void>;
  fetchBonusPaymentsList: () => void;
}

const AddBonusPaymentsForm = ({ addBonusPayment, fetchBonusPaymentsList }: AddBonusPaymentsFormProps) => {
  const [formValues, setFormValues] = useState(defaultAddBonusPaymentsForm);
  const [formErrors, setFormErrors] = useState(defaultAddBonusPaymentsErrors);
  const [isCreatingBonusPayment, setIsCreatingBonusPayment] = useState(false);
  const [saleCurrencyInput, setSaleCurrencyInput] = useState('');

  const isValidBonusPaymentForm = () => {
    let isValid = true;

    const isValidNumber = (value: string) => !isNaN(Number(value)) && Boolean(value);
    setFormErrors({
      networkId: !isValidNumber(formValues.networkId),
      networkMerchantId: !isValidNumber(formValues.networkMerchantId),
      commissionAmount: !isValidNumber(formValues.commissionAmount),
      eventDate: !Boolean(formValues.eventDate),
      saleAmount: !isValidNumber(formValues.saleAmount),
      saleCurrencyCode: !Boolean(formValues.saleCurrencyCode),
      merchantOrderId: !Boolean(formValues.merchantOrderId),
      trackingCode: !Boolean(formValues.trackingCode),
      deviceId: !isValidNumber(formValues.deviceId),
    });

    if (Object.values(formErrors).includes(true)) isValid = false;
    return isValid;
  };

  const handleSubmit = async () => {
    if (!isValidBonusPaymentForm()) {
      toast.error('Please fill out the required add bonus payments form fields');
      return;
    }

    try {
      const {
        commissionAmount,
        deviceId,
        eventDate,
        merchantOrderId,
        networkId,
        networkMerchantId,
        saleAmount,
        saleCurrencyCode,
        trackingCode,
      } = formValues;

      setIsCreatingBonusPayment(true);
      await addBonusPayment({
        CommissionAmount: Number(commissionAmount),
        DeviceID: Number(deviceId),
        EventDate: format(new Date(eventDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        MerchantOrderID: merchantOrderId,
        ModifiedAuthor: localStorage.getItem('userEmail') || wfUserAgent(),
        NetworkID: Number(networkId),
        NetworkMerchantID: Number(networkMerchantId),
        SaleAmount: Number(saleAmount),
        SaleCurrencyCode: saleCurrencyCode,
        TrackingCode: trackingCode,
      });

      setFormValues(defaultAddBonusPaymentsForm);
      setSaleCurrencyInput('');
      toast.success('Successfully created new bonus payment!');
      fetchBonusPaymentsList();
    } catch (error) {
      console.error(error);
      toast.error('Failed to create new bonus payment');
    } finally {
      setIsCreatingBonusPayment(false);
    }
  };

  const handleOnChange = (key: keyof AddBonusPaymentsFormValues, value: number | string) => {
    setFormValues({ ...formValues, [key]: value });

    if (key in formErrors && value) {
      setFormErrors({ ...formErrors, [key]: false });
    }
  };

  return (
    <div className="flex justify-center h-[370px] flex-item flex-col w-full p-4 bg-white border-[5px] border-wildfire-orange rounded-3xl shadow-md mb-[15px] grow">
      <div className="flex flex-row">
        <div className="w-1/3 m-2">
          <InputV2
            id="network-id-input"
            label="Network ID"
            value={formValues.networkId}
            error={formErrors.networkId}
            onChange={value => handleOnChange('networkId', value)}
          />
          <InputV2
            id="network-merchant-id-input"
            label="Network Merchant ID"
            value={formValues.networkMerchantId}
            error={formErrors.networkMerchantId}
            onChange={value => handleOnChange('networkMerchantId', value)}
          />
          <InputV2
            id="event-date-input"
            label="Event Date"
            type="date"
            value={formValues.eventDate}
            error={formErrors.eventDate}
            onChange={value => handleOnChange('eventDate', value)}
          />
        </div>
        <div className="w-1/3 m-2">
          <InputV2
            id="commission-amount-input"
            label="Commission Amount"
            type="number"
            value={formValues.commissionAmount}
            error={formErrors.commissionAmount}
            onChange={value => handleOnChange('commissionAmount', value)}
          />
          <InputV2
            id="sale-amount-input"
            label="Sale Amount"
            type="number"
            error={formErrors.saleAmount}
            value={formValues.saleAmount}
            onChange={value => handleOnChange('saleAmount', value)}
          />
          <Autocomplete
            label="Sale Currency Code"
            id="sale-currency-code-autocomplete"
            error={formErrors.saleCurrencyCode}
            value={saleCurrencyInput}
            onInputChange={(value: string) => setSaleCurrencyInput(value)}
            onOptionClick={(value: string) => handleOnChange('saleCurrencyCode', value)}
            options={currencyOptions}
          />
        </div>
        <div className="flex flex-col w-1/3 m-2">
          <InputV2
            id="merchant-order-id-input"
            label="Merchant Order ID"
            error={formErrors.merchantOrderId}
            value={formValues.merchantOrderId}
            onChange={value => handleOnChange('merchantOrderId', value)}
          />
          <InputV2
            id="tracking-code-input"
            label="Tracking Code"
            value={formValues.trackingCode}
            error={formErrors.trackingCode}
            onChange={value => handleOnChange('trackingCode', value)}
          />
          <InputV2
            id="device-id-input"
            label="Device ID"
            value={formValues.deviceId}
            error={formErrors.deviceId}
            onChange={value => handleOnChange('deviceId', value)}
          />
        </div>
      </div>
      <div className="flex justify-center py-4">
        <div className="w-[200px]">
          <Button label="Submit" loading={isCreatingBonusPayment} onClick={() => handleSubmit()} />
        </div>
      </div>
    </div>
  );
};

const mapActionsToProp = {
  addBonusPayment,
};
export default connect(null, mapActionsToProp)(AddBonusPaymentsForm);
