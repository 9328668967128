import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getBonusPaymentsList } from 'actions/reconciles/reconciles';
import { findErrorMessage } from 'helpers';
import FindDeviceID from 'components/BonusPayments/FindDeviceID';
import BonusPaymentsList from 'components/BonusPayments/BonusPaymentsList';
import AddBonusPayments from 'components/BonusPayments/AddBonusPayments';
import { BonusPaymentsListResponse, BonusPayment } from 'components/BonusPayments/types';
import './BonusPayments.scss';

interface BonusPaymentsProps {
  getBonusPaymentsList: (args: { pageNumber: number; status: string }) => Promise<BonusPaymentsListResponse>;
}

const BonusPayments = ({ getBonusPaymentsList }: BonusPaymentsProps) => {
  const [currentBonusPaymentsList, setCurrentBonusPaymentsList] = useState<BonusPayment[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false);
  const [listError, setListError] = useState('');

  const fetchBonusPaymentsList = async () => {
    try {
      setIsListLoading(true);
      const response = await getBonusPaymentsList({ pageNumber: activePage, status: 'PENDING' });

      setTotalPages(response.totalPages || 1);
      setCurrentBonusPaymentsList(response.bonusPayments || []);
    } catch (error) {
      setListError('Failed to retrieve bonus payments list');
      console.error(findErrorMessage(error));
    } finally {
      setIsListLoading(false);
    }
  };
  return (
    <div className="bonus-payments">
      <h1 className="bonus-payments-title">Bonus Payments</h1>
      <div className="flex flex-wrap justify-center gap-5 mt-6">
        <div>
          <FindDeviceID />
        </div>
        <div className="flex-grow min-w-[600px] max-w-[1080px]">
          <AddBonusPayments fetchBonusPaymentsList={fetchBonusPaymentsList} />
        </div>
      </div>
      <BonusPaymentsList
        activePage={activePage}
        currentBonusPaymentsList={currentBonusPaymentsList}
        totalPages={totalPages}
        isListLoading={isListLoading}
        listError={listError}
        fetchBonusPaymentsList={fetchBonusPaymentsList}
        setActivePage={setActivePage}
      />
    </div>
  );
};

const mapActionsToProp = {
  getBonusPaymentsList,
};

export default connect(null, mapActionsToProp)(BonusPayments);
