import React, { useState } from 'react';
import { subDays, addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, FormField, Input, DropdownProps, Button } from 'semantic-ui-react';
import { AppOption } from './types';
import { getDeviceIDByTrackingCode } from 'actions/reconciles/reconciles';
import './FindDeviceIDForm.scss';
import { findErrorMessage } from 'helpers';

interface FindDeviceIDFormValues {
  trackingCode: string;
  appId: string;
  eventDate: string;
}

const defaultFormValues: FindDeviceIDFormValues = {
  trackingCode: '',
  appId: '',
  eventDate: '',
};
const defaultFormErrorState = {
  trackingCode: false,
  appId: false,
  eventDate: false,
};

interface DeviceIDResponse {
  deviceId: number;
  status: number;
}

interface FindDeviceIDFormProps {
  appOptions: AppOption[];
  isFetchingAppOptions: boolean;
  hasAppOptionsError: boolean;
  errorMessage: string;
  getDeviceIDByTrackingCode: (args: {
    appId: string;
    trackingCode: string;
    eventDate: string;
  }) => Promise<DeviceIDResponse>;
}

const FindDeviceIDForm = ({
  appOptions,
  isFetchingAppOptions,
  hasAppOptionsError,
  errorMessage,
  getDeviceIDByTrackingCode,
}: FindDeviceIDFormProps) => {
  const [formValues, setFormValues] = useState<FindDeviceIDFormValues>(defaultFormValues);
  const [formErrorState, setFormErrorState] = useState(defaultFormErrorState);
  const [foundDeviceId, setFoundDeviceId] = useState<number | null>(null);
  const [isFetchingDeviceID, setIsFetchingDeviceID] = useState(false);
  const [noDeviceIDFound, setNoDeviceIDFound] = useState(false);
  const [error, setError] = useState('');
  const { appId, trackingCode, eventDate } = formValues;

  const isValidForm = () => {
    if (!trackingCode || !appId || !eventDate) {
      setFormErrorState({
        appId: !Boolean(appId),
        trackingCode: !Boolean(trackingCode),
        eventDate: !Boolean(eventDate),
      });
      return false;
    }

    return true;
  };

  const handleInputChange = (key: keyof FindDeviceIDFormValues, value: string) => {
    setFormValues({ ...formValues, [key]: value });
    setFormErrorState({ ...formErrorState, [key]: false });
  };

  const handleSubmit = async () => {
    if (!isValidForm()) {
      toast.error('Please fill out the required fields');
      return;
    }

    const tryGetDeviceID = async (date: string) => {
      try {
        if (!appId) {
          toast.error('Invalid app id.');
        }
        const response = await getDeviceIDByTrackingCode({ appId, trackingCode, eventDate: date });
        if (response.deviceId) {
          setFoundDeviceId(response.deviceId);
          setFormValues(defaultFormValues);
          toast.success('Successfully found device id.');
          return true; // Device ID found, stop further attempts
        }
      } catch (error: any) {
        const statusCode = error?.statusCode;
        // as of right now, getDeviceIDByTrackingCode returns a 500 error when there is no device id returned
        // so we by pass an errors that have a status of 500
        if (statusCode !== 500 && Boolean(statusCode)) {
          toast.error('Failed to get device id');
          console.error(findErrorMessage(error) || 'Unknown error has occurred.');
          setError(findErrorMessage(error) || 'Unknown error has occurred.');
        }
      }
      return false; // No Device ID found, continue trying
    };

    try {
      setIsFetchingDeviceID(true);
      setFoundDeviceId(null);
      setNoDeviceIDFound(false);

      const eventDateFormatted = formatInTimeZone(new Date(eventDate), 'UTC', 'yyyy-MM-dd');
      const dayBefore = formatInTimeZone(subDays(new Date(eventDate), 1), 'UTC', 'yyyy-MM-dd');
      const dayAfter = formatInTimeZone(addDays(new Date(eventDate), 1), 'UTC', 'yyyy-MM-dd');

      const datesToTry = [eventDateFormatted, dayBefore, dayAfter];
      let foundDeviceID = false;

      // tries dates in order
      for (const date of datesToTry) {
        foundDeviceID = await tryGetDeviceID(date);
        if (foundDeviceID) break; // stop request if Device ID is found
      }

      if (!foundDeviceID) {
        setNoDeviceIDFound(true);
        toast.error('No device id found.');
      }
    } finally {
      setIsFetchingDeviceID(false);
    }
  };

  return (
    <div className="find-device-id-form-container h-[370px]">
      <div className="find-device-id-form-fields">
        <Form onSubmit={handleSubmit}>
          <FormField
            control={Input}
            label="Tracking Code"
            placeholder="Tracking Code"
            value={formValues.trackingCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('trackingCode', e.target.value)}
            error={formErrorState.trackingCode}
          />
          <FormField error={formErrorState.appId}>
            <label htmlFor="application-id-selector">Application ID</label>
            <Form.Dropdown
              selection
              clearable
              search
              id="application-id-selector"
              loading={isFetchingAppOptions}
              disabled={hasAppOptionsError}
              value={appId}
              options={appOptions}
              onChange={(e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                // used to handle clear case of dropdown
                if (data.value === null || data.value === undefined || data.value === '') {
                  handleInputChange('appId', '');
                  return;
                }

                // makes sure that the value is a valid application id
                if (Number.isNaN(Number(data.value))) {
                  console.error('Invalid application id.');
                  return;
                }

                handleInputChange('appId', data.value.toString() || '');
              }}
              placeholder="Application ID"
            />
          </FormField>
          <FormField error={formErrorState.eventDate}>
            <label htmlFor="event-date-selector">Event Date</label>
            <Form.Input
              control={Input}
              id="event-date-selector"
              type="date"
              value={eventDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange('eventDate', e.target.value);
              }}
              placeholder="Event Date"
            />
          </FormField>
          <div className="button-container">
            <Button
              type="submit"
              primary
              className="find-device-id-form-button font-montserrat"
              disabled={isFetchingDeviceID || hasAppOptionsError}
              loading={isFetchingDeviceID}
            >
              Submit
            </Button>
          </div>
        </Form>
        <div className="mt-4 text-center">
          {foundDeviceId && (
            <p className="find-device-id-form-message">
              Device ID Found: <span style={{ fontWeight: 'bold' }}>{foundDeviceId}</span>
            </p>
          )}
          {noDeviceIDFound && <p className="find-device-id-form-message">Device ID not found</p>}
          {(error || errorMessage) && <p style={{ fontSize: '16px', color: 'red' }}>{error || errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

const mapActionsToProp = {
  getDeviceIDByTrackingCode,
};

export default connect(null, mapActionsToProp)(FindDeviceIDForm);
