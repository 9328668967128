import React from 'react';
import AddBonusPaymentsForm from './AddBonusPaymentsForm';

interface AddBonusPaymentsProps {
  fetchBonusPaymentsList: () => void;
}

const AddBonusPayments = ({ fetchBonusPaymentsList }: AddBonusPaymentsProps) => {
  return (
    <div className="flex flex-col flex-item grow">
      <h2 className="self-center">Add Bonus Payments</h2>
      <AddBonusPaymentsForm fetchBonusPaymentsList={fetchBonusPaymentsList} />
    </div>
  );
};

export default AddBonusPayments;
